import { CSSProperties, useEffect, useRef, useState } from "react";
import { Polygon, Tooltip } from "react-leaflet";
import "./styles.css";

import {
  FlyToBounds,
  FlyToBoundsRefProps,
  IAnimationState,
} from "../../../../components/Leaflet/FlyToBounds/Index";
import { SimpleButtonClose } from "../../../../components/Modal/Contents/SimpleButtonClose/Index";
import { ButtonOnlyTitle } from "../../../../components/Buttons/ButtonOnlyTitle/Index";
import { LegendRounded } from "../../../../components/PaddocksMap/LegendRounded/Index";
import ContainerZoom1X from "../../../../components/Leaflet/ContainerZoom1X/Index";
import ContainerZoom4X from "../../../../components/Leaflet/ContainerZoom4X/Index";
import ContainerZoom2X from "../../../../components/Leaflet/ContainerZoom2X/Index";
import ContainerZoom3X from "../../../../components/Leaflet/ContainerZoom3X/Index";
import { PolylineFarm } from "../../../../components/PaddocksMap/PolylineFarm/Index";
import { BatchesIcons, ICustomBatch } from "../../../../components/PaddocksMap/BatchesIcons/Index";
import { TitleModal } from "../../../../components/Modal/Contents/TitleModal/Index";
import { LabelFarm1X } from "../../../../components/PaddocksMap/LabelFarm1X/Index";
import { TextModal } from "../../../../components/Modal/Contents/TextModal/Index";
import { AnimatedZoom } from "../../../../components/Leaflet/AnimatedZoom/Index";
import { AlertModal } from "../../../../components/Modal/Alert/Index";
import { useFilter } from "../../../../hooks/useFilter";

import { getCenterCoords } from "../../../../utils/leaflet/getCenterCoords";
import Constants from "../../../../constants/Index";
import api from "../../../../services/axios";

import { ICoords } from "../../../../@types/GoogleMaps/ICoord";
import { CustomControls } from "../../../../components/Leaflet/CustomControls/Index";
import { SpecsMouseOver } from "../../../../components/PaddocksMap/SpecsMouseOver/Index";
import { BatchesSpecsDetails } from "../../../../components/PaddocksMap/BatchesSpecsDetails";
import { IBatch } from "../../../../@types/API/IBatches";


type IFarmData = {
  address: {
    city: { code: '5007109', name: 'Ribas do Rio Pardo' },
    state: string
    street: string
  },
  document: string,
  id: number,
  initials: string,
  map_coords: ICoords
  name: string
  owner_user: { id: number, name: string }
  properties: { season: { dry_initial: number, rain_initial: number } }
  state_registration: string
}

type IAreaData = {
  manager_user: { id: number, name: string },
  map_coords: ICoords,
  indoor_grazing_time: number,
  outdoor_grazing_time: number,
  id: number,
  name: string,
  initials: string,
  acreage: number,
  farm: {
    map_coords: ICoords,
    id: number,
    name: string,
    initials: string
  }
}

type IMicroAreaData = {
  acreage: number,
  area: IEntityAreaORFarm
  farm: IEntityAreaORFarm
  id: number
  initials: string
  map_coords: ICoords,
  grazing_begin: string,
  grazing_end: string,
  micro_area_route: {
    batch: { id: number, initials: string }, current_micro_area: boolean
  }
  management: {
    type: {
      monitoring_types: any[];
      id: number;
      name: string;
    }
  };
}



type IEntityAreaORFarm = { map_coords: ICoords, id: number, name: string, initials: string }

const contentStyleAlert = {
  width: "440px",
  height: "218px",
  borderRadius: "20px",
  overflow: "hidden",
  zIndex: 999,
} as CSSProperties;

export function PastureManagement() {
  const { farmFilter, areaFilter, defaultBGFarm, setHideMicroAreaFilter, setHideBatchFilter } = useFilter();

  const flytoBoundsRef = useRef<FlyToBoundsRefProps>();
  const [stateOfAnimation, setStateOfAnimation] = useState<IAnimationState>("end");
  const [farms, setFarms] = useState<IFarmData[]>([]);
  const [areas, setAreas] = useState<IAreaData[]>([]);
  const [microAreas, setMicroAreas] = useState<IMicroAreaData[]>([]);
  const [batches, setBatches] = useState<IBatch[]>([]);
  const [batchesIcons, setBatchesIcons] = useState<ICustomBatch[]>([]);
  const [errorModal, seterrorModal] = useState<boolean>(false);

  function overrideCentermap() {
    setStateOfAnimation("start");
    if (flytoBoundsRef && flytoBoundsRef.current && farmFilter)
      flytoBoundsRef.current?.flyToBounds(farmFilter?.coords);
  }

  const toggleAlert = () => seterrorModal((visible) => !visible);

  const getFarmsAllFarmsByUser = () => {
    api.get<IFarmData[]>('/farms/user')
      .then(({ data: farms }) => {
        setFarms(farms)
        getAllBatchesByUser(farms)
      })
      .catch(toggleAlert)
  }

  const getFarmsAllAreasByUser = () => {
    api.get<IAreaData[]>('/areas/user')
      .then(({ data: areas }) => setAreas(areas))
      .catch(toggleAlert)
  }

  const getAllBatchesByUser = (farms: IFarmData[]) => {
    farms.map(farm => {
      api.get<IBatch[]>(`/batches`, { params: { farm_id: farm.id } })
        .then(({ data: batches }) => {
          setBatches(prev => [...prev, ...batches])
          const batchIcons = batches.map(e => ({
            initials: e.initials,
            coords: getCenterCoords(e.current_micro_area.map_coords),
            id: e.id.toString(),
            areaId: e.area.id.toString(),
            visible: true
          }) as ICustomBatch)
          setBatchesIcons(prev => [...prev, ...batchIcons])
        })
        .catch(toggleAlert)
    })
  }

  const getBatchById = (id: number) => {
    return batches.find(batch => batch.id == id.toString());
  };

  const getFarmsAllMicroAreasByUser = () => {
    api.get<IMicroAreaData[]>('/micro-areas')
      .then(({ data: microAreas }) => setMicroAreas(microAreas))
      .catch(toggleAlert)
  }

  const handleBGOppacity = (farm: IFarmData, area: IEntityAreaORFarm) => {
    if (areaFilter && area.id.toString() !== areaFilter.id.toString()) return 0.2;
    if (farm.id.toString() !== farmFilter.id.toString()) return 0.2;
    return 0.55;
  }

  const handleCurrentColor = (microArea: IMicroAreaData) => {
    switch (microArea.management.type.id) {
      case 1: // Sem monitoramento
        return Constants.PADDOCKTIME_COLORS.WHITE
      case 2: // Contínuo
        return Constants.PADDOCKTIME_COLORS.ORANGE
      case 3: // Rotacionado
        return Constants.PADDOCKTIME_COLORS.RED
      case 4: // Em faixa
        return Constants.PADDOCKTIME_COLORS.BLUE
    }
  }

  useEffect(() => {
    document.title = Constants.TITLE_PADDOCKS_MAP_PASTURE_MANAGEMENT;

    getFarmsAllFarmsByUser()
    getFarmsAllAreasByUser()
    getFarmsAllMicroAreasByUser()
  }, [, farmFilter])

  useEffect(() => {
    setBatches([])
    setBatchesIcons([])

    if (flytoBoundsRef && flytoBoundsRef.current && farmFilter)
      flytoBoundsRef.current?.flyToBounds(farmFilter?.coords);
  }, [farmFilter])

  return (
    <div className="defaultmap__container">
      <AlertModal visible={errorModal} animation="slideUp" contentStyle={contentStyleAlert}>
        <section className="defaultmap__alert--content">
          <SimpleButtonClose onClick={toggleAlert} />
          <div className="defaultmap__alert--texts">
            <TitleModal>{Constants.MESSAGE_INVALID_MODAL_TITLE}</TitleModal>
            <TextModal>{Constants.MESSAGE_INVALID_MODAL_FILTER}</TextModal>
          </div>
          <ButtonOnlyTitle onClick={toggleAlert} title="voltar" theme="info" />
        </section>
      </AlertModal>

      <FlyToBounds ref={flytoBoundsRef} onStateAnimation={(state) => setStateOfAnimation(state)}>
        {farms.map(farm => {
          return <AnimatedZoom key={farm.id} coordsToBoundZoom={farm.map_coords} updateZoomVisualization={stateOfAnimation}>
            <ContainerZoom1X>
              <LabelFarm1X
                key={farm.initials}
                position={getCenterCoords(farm.map_coords)}
                initials={farm.initials}
              />
            </ContainerZoom1X>

            <ContainerZoom2X>
              <Polygon
                key={farm.id}
                pathOptions={{ fillColor: defaultBGFarm }}
                positions={farm.map_coords}
                color={Constants.PASTUREMANAGEMENT_COLORS.WHITE}
                fillOpacity={0.55}
                fill={true}
                weight={2}
                noClip
              />
            </ContainerZoom2X>

            <ContainerZoom3X>
              <PolylineFarm path={farm.map_coords} />
              <BatchesIcons batches={batchesIcons} />
              {areas
                .filter((microArea) => microArea.farm.id === farm.id)
                .map((area) => (
                  <Polygon
                    key={area.id}
                    pathOptions={{
                      fillOpacity: handleBGOppacity(farm, area),
                      color: Constants.PASTUREMANAGEMENT_COLORS.WHITE,
                      fillColor: Constants.PASTUREMANAGEMENT_COLORS.ORANGE,
                    }}
                    color={Constants.PASTUREMANAGEMENT_COLORS.WHITE}
                    positions={area.map_coords}
                    fill={true}
                    weight={2}
                    noClip
                  />
                ))}
            </ContainerZoom3X>

            <ContainerZoom4X>
              <BatchesIcons batches={batchesIcons} />
              <PolylineFarm path={farm.map_coords} />
              {microAreas
                .filter((microArea) => microArea.farm.id === farm.id)
                .map((microArea) => <Polygon
                  key={microArea.id}
                  pathOptions={{
                    fillOpacity: handleBGOppacity(farm, microArea.area),
                    color: Constants.PASTUREMANAGEMENT_COLORS.WHITE,
                    fillColor: handleCurrentColor(microArea),
                  }}
                  positions={microArea.map_coords}
                  fill={true}
                  weight={2}
                  noClip
                >
                  <Tooltip className="customToolTip__container" sticky>
                    <SpecsMouseOver
                      className={microArea?.micro_area_route?.batch?.id ? "havebatch" : ""}
                      microAreaLabel={`${microArea?.farm?.initials}-${microArea.area.initials}-${microArea.initials}`}
                      type={"batch"}
                    />
                    {microArea?.micro_area_route && (
                      <BatchesSpecsDetails
                        batchLabel={`${microArea?.farm?.initials}-${microArea?.area?.initials}-${microArea?.micro_area_route?.batch?.initials}`}
                        qtdLabel={`${getBatchById(microArea.micro_area_route.batch.id)?.animals_quantity} animais`}
                        capacity={`${getBatchById(microArea.micro_area_route.batch.id)?.batch_capacity_rate}`}
                      />
                    )}
                  </Tooltip>
                </Polygon>
                )}
            </ContainerZoom4X>
          </AnimatedZoom>
        })}

      </FlyToBounds>
      <CustomControls overrideCentermap={overrideCentermap} />
      <section className="pasturemanagement__footerlegend--container">
        <h6>TIPO DE MANEJO</h6>
        <div className="pasturemanagement__footerlegend--content">
          <LegendRounded label="Contínuo" bgColor={Constants.PASTUREMANAGEMENT_COLORS.ORANGE} />
          <LegendRounded label="Rotacionado" bgColor={Constants.PASTUREMANAGEMENT_COLORS.RED} />
          <LegendRounded label="Em faixa" bgColor={Constants.PASTUREMANAGEMENT_COLORS.BLUE} />
          <LegendRounded label="Sem monitoramento" bgColor={Constants.PASTUREMANAGEMENT_COLORS.WHITE} />
        </div>
      </section>
    </div>
  );
}
