import { CSSProperties, useEffect, useRef, useState } from "react";
import { Scope } from "@unform/core";
import { Form } from "@unform/web";
import * as yup from "yup";
import "./styles.css";
import { IOptionsProps } from "../../../../@types/reactSelect/IOption";
import { IProfiles } from "../../../../@types/app/IProfile";
import { FooterControlls } from "../../../../components/Onboarding/Template/FooterControlls/Index";
import { SimpleButtonClose } from "../../../../components/Modal/Contents/SimpleButtonClose/Index";
import { CreateProfile } from "../../../../components/Onboarding/Register/CreateProfile/Index";
import { ButtonRedirect } from "../../../../components/Modal/Error/ButtonRedirect/Index";
import { ButtonOnlyTitle } from "../../../../components/Buttons/ButtonOnlyTitle/Index";
import { TitleModal } from "../../../../components/Modal/Contents/TitleModal/Index";
import { TextModal } from "../../../../components/Modal/Contents/TextModal/Index";
import { AlertModal } from "../../../../components/Modal/Alert/Index";
import {
  useYupOnlyVerify,
  useYupValidate,
} from "../../../../hooks/useYupValidate";
import { useOnboarding } from "../../../../hooks/useOnboarding";
import Constants from "../../../../constants/Index";
import axios from "../../../../services/axios";
import { generateuuid } from "../../../../utils/uuid/generate";
import { EditValues } from "../../../../hooks/EditValues";
import { TextInput } from "../../../../components/Inputs/TextInputs/TextInput/Index";
import jwt_decode from "jwt-decode";

const baseProfile = {
  profile_id: 0,
  profile: null,
  name: "",
  phone: "",
  uuid: "",
};

const contentStyle = {
  width: "440px",
  height: "218px",
  background: "#FFFFFF",
  borderRadius: "20px",
  padding: "20px",
} as CSSProperties;

interface CreateProfilesProps {
  onlyRead?: boolean;
  onBoarding?: boolean;
}

export function CreateProfiles({
  onlyRead = false,
  onBoarding = false,
}: CreateProfilesProps) {
  yup.addMethod(yup.array, "unique", function (mapper = (a: any) => a) {
    return this.test("unique", function (list: any) {
      let index = -1;
      list.forEach((l: any, i: number) => {
        const lenght = list.filter((a: any) => a.phone === l.phone).length;
        if (lenght > 1) index = i;
      });
      if (index > -1)
        return this.createError({
          path: `users[${index}].phone`,
          message: Constants.MESSAGE_UNIQUE_PHONE,
        });
      return true;
    });
  });

  const {
    setBlockNextStep,
    setPayload,
    payload,
    advanceStep,
    userProgress,
    screenNumber,
    subScreenNumber,
  } = useOnboarding();

  const [profilesList, setProfilesList] = useState<IOptionsProps>([]);

  const [profilesData, setProfilesData] = useState<IProfiles>([baseProfile]);

  const [alertVisible, setAlertVisible] = useState<boolean>(false);
  const [numProfiles, setNumProfiles] = useState<number>(0);

  const formRef = useRef<HTMLFormElement>();

  const mainRef = useRef(null);

  const { setFormRefValue, setBlockSave, refreshValues, permissionMaster } =
    EditValues();

  var token = localStorage.getItem("token");

  const props = {
    profile_id: yup
      .object({
        label: yup.string(),
        value: yup.string(),
      })
      .nullable()
      .required("Selecione o Cargo/Função"),
    name: yup
      .string()
      .matches(/^[^\s]+(\s+[^\s]+)*$/, "Escreva um nome válido")
      .min(3, "Necessário ter no mínimo 3 letras")
      .max(120, "No máximo 120 letras")
      .required("*Campo obrigatório não informado."),
    phone: yup
      .string()
      .min(11, "Telefone informado inválido!")
      .max(11, "Telefone informado inválido!")
      .test('value', 'Telefone ja cadastrado para o usuario Master. ', (value) => {
        if (token && onBoarding && (value == (jwt_decode(token) as any).sub)) return false
        return true
      })
      .required("*Campo obrigatório não informado."),
  };

  const profileForm = yup.object().shape({
    users: yup
      .array()
      .of(yup.object().shape(props)) //@ts-ignore
      .unique((a) => a.phone),
  });

  async function handleFormSubmit() {
    const formData: any = formRef.current?.getData();
    const isValid = await useYupValidate(formRef, formData, profileForm);
    if (isValid) {
      let profiles: IProfiles = formData.users;
      profiles = profiles.map((profile) => {
        return {
          ...profile,
          password: Constants.DEFAULT_PASSWORD,
          profile_id: profile.profile?.value,
          uuid: profile.uuid ? profile.uuid : generateuuid(),
        };
      });
      setPayload((payload) => {
        const response = { ...payload, users: profiles };
        localStorage.setItem(
          Constants.LOCALSTORAGE_PAYLOAD,
          JSON.stringify(response)
        );
        return response;
      });
      setAlertVisible(true);
    }
    return false;
  }

  // Isso monitora os campos obrigatórios e libera/bloqueia o avançar
  async function handleChangesProfile(input?: any) {
    let name = undefined,
      isValid = false;
    if (!onlyRead) {
      if (input && input.props && input.props.name) name = input.props.name;
      else if (input) name = input.name;
      const formdata = formRef.current?.getData();
      if (name !== undefined) {
        const validate = {
          //@ts-ignore
          [name]: props[name],
        };
        const schemaValidation = yup.object().shape({
          users: yup
            .array()
            .of(yup.object().shape({ ...validate }))
            //@ts-ignore
            .unique((a) => a.phone),
        });

        isValid = await useYupValidate(formRef, formdata, schemaValidation);
      } else isValid = await useYupValidate(formRef, formdata, profileForm);
      setBlockNextStep(!isValid);
    } else {
      const formData: any = formRef.current?.getData();
      isValid = await useYupValidate(formRef, formData, profileForm);
      setBlockSave(!isValid);
      setFormRefValue(formRef as any);
    }
    return isValid;
  }

  // Isso adiciona um novo perfil
  async function addNewProfile() {
    const isValid = await handleChangesProfile(true);
    if (isValid && !onlyRead) {
      setBlockNextStep(true);
      setProfilesData((profiles) => {
        return [...profiles, baseProfile];
      });
    } else if (isValid && onlyRead) {
      setBlockSave(true);
      setProfilesData((profiles) => {
        return [...profiles, baseProfile];
      });
    }
  }

  // Isso remove um perfil da lista de perfis
  function removeProfile(id: number) {
    const profiles: IProfiles = formRef.current?.getData().users;
    if (profiles) {
      const profilesFiltered = profiles.filter(
        (profile, index) => index !== id
      );
      setProfilesData(() => profilesFiltered);
      formRef.current?.setData({ users: profilesFiltered });
      setTimeout(() => handleChangesProfile(), 1);
    }
  }

  useEffect(() => {
    setProfilesData([baseProfile]);
    axios
      .get("/profiles")
      .then(({ data }) => {
        setProfilesList(() =>
          data.map((e: any) => ({ label: e.name, value: e.id }))
        );
      })
      .catch((e) => console.log(e));

    if (onlyRead) {
      axios
        .get("/users/user")
        .then(({ data }) => {
          setProfilesData(data);
          setNumProfiles(data.length);
          data.map(
            (value: any) =>
            (value.profile = {
              label: value.profile_name,
              value: value.profile_name,
            })
          );
          formRef.current?.setData({ users: data });
        })
        .catch((e) => console.log(e));
    } else {
      setBlockNextStep(true);
      if (payload && payload.users) {
        setProfilesData(() => payload.users);
        formRef.current?.setData({ users: payload.users });
      }
    }
  }, [, refreshValues]);

  useEffect(() => {
    const formdata = formRef.current?.getData();
    if (!onlyRead)
      useYupOnlyVerify(formRef, formdata, profileForm).then((isValid) =>
        setBlockNextStep(!!isValid)
      );
  }, [profilesList]);

  // Função que avança o passo manualmente
  function nextStep() {
    advanceStep(userProgress, screenNumber, subScreenNumber);
  }

  return (
    <>
      <AlertModal visible={alertVisible} contentStyle={contentStyle}>
        <SimpleButtonClose onClick={() => setAlertVisible(false)} />
        <div className="createuser__alertModalBody">
          <TextModal>{Constants.MESSAGE_ALERT_CREATEUSER}</TextModal>
          <TitleModal>{Constants.DEFAULT_PASSWORD}</TitleModal>
        </div>
        <ButtonRedirect onClick={nextStep}>Continuar</ButtonRedirect>
      </AlertModal>

      <main ref={mainRef}>
        <div className="createuser__container">
          <div className="onboarding-main-content">
            {onBoarding && <h1 id="onboarding-title-page">Perfis</h1>}

            <Form
              initialData={{ users: profilesData }}
              className="form__createuser"
              ref={formRef as any}
              onSubmit={() => { }}
            >
              <TextInput hidden={true} value={"profile"} name="form" />
              {profilesData.map((profile, index) => (
                <Scope key={index} path={`users[${index}]`}>
                  <CreateProfile
                    onAnywhereChanges={
                      index >= numProfiles ? handleChangesProfile : undefined
                    }
                    onRemoveProfile={removeProfile}
                    profilesList={profilesList}
                    uuid={index}
                    isVisibleBtnRemoveProfile={
                      onlyRead
                        ? index >= numProfiles
                        : profilesData.length > 1 || index > 0
                    }
                    onlyRead={onlyRead && !(index >= numProfiles)}
                  />
                </Scope>
              ))}
            </Form>
            {(onBoarding || permissionMaster) && (
              <ButtonOnlyTitle
                title="+ adicionar outro perfil"
                theme="info"
                onClick={addNewProfile}
              />
            )}
          </div>
        </div>
      </main>
      {!onlyRead && (
        <footer>
          <FooterControlls nextStepEvent={handleFormSubmit} />
        </footer>
      )}
    </>
  );
}
