import { createContext, ReactNode, useEffect, useState } from "react";
import { ICoord, ICoords } from "../@types/GoogleMaps/ICoord";
import { IOptionProps } from "../@types/reactSelect/IOption";
import Constants from "../constants/Index";
import { DateRange } from "react-day-picker";
import { subDays } from "date-fns";

type FilterContextProviderProps = {
  children: ReactNode;
};

type ICustomFarm = {
  id: string;
  initials: string;
  coords: ICoords;
  name: string;
  bgColor: string;
};

type ICustomBatch = {
  initials: string;
  coords: ICoord;
  id: string;
  farmId: string;
  areaId: string;
  capacityRate: number;
  microAreaId: string;
};

type ICustomMicroAreaFiltered = {
  id: string;
  areaId: string;
  coords: ICoords;
  initials: string;
  microAreaRoute: {
    current: boolean;
    batch: {
      id: string;
      initials: string;
    };
  };
};

type ICustomArea = {
  id: string;
  initials: string;
  coords: ICoords;
  name: string;
  bgColor: string;
  farmId: string;
};

export type IOptionsSideBarLinks =
  'DASHBOARD' |
  'MAPA' |
  'PASTURE_EVALUATION' |
  'MANAGER_SYSTEM' |
  'PROFILES' |
  'LOGOUT'

export type IOptionsDashboards =
  null |
  'CATTLE_MAP' |
  'SUPPLEMENTATION'

export type IOptionsSystemManagerLinks =
  null |
  'PASTURE_MANAGEMENT' |
  'MANAGER_EVALUATORS' |
  'PROPERTIES_AREAS' |
  'CREATE_BATCH' |
  'TASK_EVALUATION' |
  'CATTLE_MAP' |
  'PRODUCTS'

type valuesContext = {
  farmFilter: ICustomFarm;
  areaFilter: ICustomArea;
  microAreaFilter: ICustomMicroAreaFiltered;
  hideMicroAreaFilter: boolean;
  hideBatchFilter: boolean;
  microAreaDefaultOption: IOptionProps;
  batchDefaultOption: IOptionProps;
  sideBarOptionSelected: IOptionsSideBarLinks;
  defaultBGFarm: string;
  batchFilter: ICustomBatch;
  modalCalendarFilter: boolean;
  setModalCalendarFilter: (s: boolean) => void;
  setFarmFilter: (s: ICustomFarm | undefined) => void;
  setAreaFilter: (s: ICustomArea | undefined) => void;
  setMicroArea: (s: ICustomMicroAreaFiltered | undefined) => void;
  setBatchFilter: (s: ICustomBatch | undefined) => void;
  setHideMicroAreaFilter: (s: boolean) => void;
  setHideBatchFilter: (s: boolean) => void;
  setMicroAreaDefaultOption: (s: IOptionProps | undefined) => void;
  setBatchDefaultOption: (s: IOptionProps | undefined) => void;
  setSideBarOptionSelected: (value: IOptionsSideBarLinks) => void;
  systemManager: IOptionsSystemManagerLinks;
  setSystemManager: (value: IOptionsSystemManagerLinks) => void;
  dashboard: IOptionsDashboards;
  setDashboard: (value: IOptionsDashboards) => void;
  rangeDays: DateRange | undefined;
  setRangeDays: (value: DateRange | undefined) => void;
  viewRangeDays: DateRange | undefined;
  setViewRangeDays: (value: DateRange | undefined) => void;
  balance: IBalance[];
  setBalance: (value: IBalance[]) => void;
  balanceCapacity: IBalanceCapacity[];
  setBalanceCapacity: (value: IBalanceCapacity[]) => void;
  areasIdsFilter: IOptionProps[];
  setAreasIdsFilter: (value: IOptionProps[]) => void;
  focusLastDay: boolean;
  setFocusLastDay: (s: boolean) => void;
};

export const FilterContext = createContext({} as valuesContext);

export const FilterContextProvider = ({ children }: FilterContextProviderProps) => {
  const [farm, setFarm] = useState<ICustomFarm | undefined>(undefined);
  const [area, setArea] = useState<ICustomArea | undefined>();
  const [microArea, setMicroArea] = useState<ICustomMicroAreaFiltered | undefined>(undefined);
  const [batch, setBatch] = useState<ICustomBatch | undefined>(undefined);
  const [microAreaDefaultOption, setMicroAreaDefaultOption] = useState<IOptionProps | undefined>(undefined);
  const [batchDefaultOption, setBatchDefaultOption] = useState<IOptionProps | undefined>(undefined);
  const [option, setOption] = useState<IOptionsSideBarLinks>('MAPA');
  const [systemManager, setSystemManager] = useState<IOptionsSystemManagerLinks>(null);
  const [dashboard, setDashboard] = useState<IOptionsDashboards>(null);
  const [defaultBGFarm, setDefaultBGFarm] = useState<string>("");
  const [areaFilter, setAreaFilter] = useState<boolean>(false);
  const [lotsFilter, setLotsFilter] = useState<boolean>(false);
  const [modalCalendarFilter, setModalCalendarFilter] = useState<boolean>(false);
  const [balance, setBalance] = useState<IBalance[]>();
  const [balanceCapacity, setBalanceCapacity] = useState<IBalanceCapacity[]>();
  const [areasIdsFilter, setAreasIdsFilter] = useState<IOptionProps[]>([]);
  const [focusLastDay, setFocusLastDay] = useState<boolean>(false);
  const [rangeDays, setRangeDays] = useState<DateRange | undefined>(undefined);
  const [viewRangeDays, setViewRangeDays] = useState<DateRange | undefined>(undefined);

  function getRandomColor() {
    const colors: Array<string> = Object.values(Constants.GRAZINGMAP_COLORS);
    return colors[Math.floor(Math.random() * colors.length)];
  }

  useEffect(() => {
    setDefaultBGFarm((prevColor) => (prevColor ? prevColor : getRandomColor()));
  }, []);

  useEffect(() => {
    setSystemManager(null)
    setFocusLastDay(false)
    setRangeDays({
      from: subDays(new Date(), 365),
      to: new Date()
    })
    setViewRangeDays({
      from: subDays(new Date(), 365),
      to: new Date()
    })
  }, [option]);

  useEffect(() => {
    setRangeDays({
      from: new Date(),
      to: new Date()
    })
    setViewRangeDays({
      from: new Date(),
      to: new Date()
    })
  }, [dashboard]);

  return (
    <FilterContext.Provider
      value={
        {
          farmFilter: farm,
          setFarmFilter: setFarm,
          areaFilter: area,
          microAreaFilter: microArea,
          setAreaFilter: setArea,
          sideBarOptionSelected: option,
          setSideBarOptionSelected: setOption,
          defaultBGFarm,
          setMicroArea,
          hideMicroAreaFilter: areaFilter,
          hideBatchFilter: lotsFilter,
          setHideMicroAreaFilter: setAreaFilter,
          setHideBatchFilter: setLotsFilter,
          microAreaDefaultOption,
          setMicroAreaDefaultOption,
          setBatchFilter: setBatch,
          batchFilter: batch,
          setBatchDefaultOption,
          batchDefaultOption,
          modalCalendarFilter,
          setModalCalendarFilter,
          systemManager,
          setSystemManager,
          dashboard,
          setDashboard,
          rangeDays,
          setRangeDays,
          balance,
          setBalance,
          balanceCapacity,
          setBalanceCapacity,
          areasIdsFilter,
          setAreasIdsFilter,
          focusLastDay,
          setFocusLastDay,
          viewRangeDays,
          setViewRangeDays
        } as valuesContext
      }
    >
      {children}
    </FilterContext.Provider>
  );
};
